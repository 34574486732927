import React from 'react';
import {Helmet} from 'react-helmet';
import Layout from '../components/layout';
import PostMeta from '../components/post-meta';
import {Link, graphql} from 'gatsby';
import {useSiteMetadata} from '../utils/use-site-metadata';

export default function Template({pageContext, data}) {
  const {tag} = pageContext;
  const {edges} = data.allMarkdownRemark;
  const {title, description, siteUrl} = useSiteMetadata();

  return (
    <Layout>
      <Helmet>
        <title>
          {tag} — {title} · {description}
        </title>
        <meta property="og:title" content={tag} />
        <meta property="og:url" content={`${siteUrl}/tags/${tag}/`} />
      </Helmet>
      <div className="tag__header">
        <h1 className={`tag__header-title tags-${tag.toLowerCase()}`}>{tag}</h1>
      </div>
      <ul className="post-listing">
        {edges.map(({node: post}) => {
          return (
            <li className="post-teaser" key={post.frontmatter.path}>
              <Link className="post-teaser__title" to={post.frontmatter.path}>
                {post.frontmatter.title}
              </Link>
              <PostMeta post={post} />
            </li>
          );
        })}
      </ul>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: {fields: [frontmatter___date], order: DESC}
      filter: {frontmatter: {tags: {in: [$tag]}}}
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            author
            date
            shortDate: date(formatString: "MMMM Do, YYYY")
            title
            path
          }
        }
      }
    }
  }
`;
