import React from 'react';
import {avatar, url} from '../utils/use-github';

export default function PostMeta({post}) {
  return (
    <div className="post-meta">
      <a className="post-meta__user-avatar" href={url(post.frontmatter.author)}>
        <img
          className="post-meta__user-avatar-image"
          alt=""
          src={avatar(post.frontmatter.author, 80)}
        />
      </a>
      <div className="post-meta__info">
        <a
          className="post-meta__user-profile"
          href={url(post.frontmatter.author)}
        >
          {post.frontmatter.author}
        </a>

        <time
          className="post-meta__time"
          dateTime={post.frontmatter.date}
          title={post.frontmatter.date}
        >
          {post.frontmatter.shortDate}
        </time>
      </div>
    </div>
  );
}
